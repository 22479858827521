import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import About from '../views/AboutPage.vue'
import Trails from '../views/TrailsPage.vue'
import Contact from '../views/ContactPage.vue'
import Emmitsburg from '../views/EmmitsburgPage.vue'
import Fairland from '../views/FairlandPage.vue'

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/about', name: 'About', component: About },
    { path: '/trails', name: 'Trails', component: Trails },
    { path: '/trails/emmitsburg', name: 'Emmitsburg', component: Emmitsburg },
    { path: '/trails/fairland', name: 'Fairland', component: Fairland },
    { path: '/contact', name: 'Contact', component: Contact },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
