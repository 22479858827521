<template>
  <flowbite-themable theme="green">
    <fwb-navbar class="bg-white shadow-md z-50 relative py-4">
      <template #logo>
        <RouterLink to="/" class="flex items-center">
          <img :src="logoUrl" alt="Trail Conservancy logo" style="width: 120px; height: 99px;">
          <span class="ml-3 text-4xl font-semibold text-gray-900">Trail Conservancy</span>
        </RouterLink>
      </template>
      <template #default="{isShowMenu}">
        <fwb-navbar-collapse :is-show-menu="isShowMenu">
          <RouterLink to="/" class="text-lg text-gray-700 hover:text-green-custom">
            Home
          </RouterLink>
          <RouterLink to="/about" class="text-lg text-gray-700 hover:text-green-custom">
            About
          </RouterLink>
          <fwb-dropdown text="Trails" class="relative text-lg">
            <template #trigger>
              <button class="flex items-center text-gray-700 hover:text-green-custom">
                Trails
                <svg class="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06-.02l3.72 3.58 3.72-3.58a.75.75 0 011.06 1.06l-4.25 4.09a.75.75 0 01-1.06 0L5.25 8.27a.75.75 0 01-.02-1.06z" clip-rule="evenodd"></path>
                </svg>
              </button>
            </template>
            <div class="absolute z-10 w-52 bg-white rounded shadow-md">
              <RouterLink to="/trails/emmitsburg" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                Emmitsburg
              </RouterLink>
              <RouterLink to="/trails/fairland" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                Fairland
              </RouterLink>
            </div>
          </fwb-dropdown>
          <RouterLink to="/contact" class="text-lg text-gray-700 hover:text-green-custom">
            Contact Us
          </RouterLink>
        </fwb-navbar-collapse>
      </template>
    </fwb-navbar>
  </flowbite-themable>
</template>

<script setup>
import {
  FwbNavbar,
  FwbNavbarCollapse,
  FwbDropdown,
  FlowbiteThemable,
} from 'flowbite-vue';
import { RouterLink } from 'vue-router';

const logoUrl = require('@/assets/logo.jpg');
</script>

<style scoped>
a.flex.items-center {
  display: flex;
  align-items: center;
}

img {
  height: 99px;
  width: 129px;
}

span.ml-3 {
  font-size: 36px;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: #313131;
  margin-left: 12px;
  font-weight: 400;
}

.text-green-custom {
  color: #9DB668 !important;
}
</style>
