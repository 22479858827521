// File: src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css'; // Import Tailwind CSS
import 'flowbite';
import '../node_modules/flowbite-vue/dist/index.css'; // Import Flowbite Vue styles

createApp(App).use(router).mount('#app')

// Initialize Flowbite JavaScript
import { Carousel } from 'flowbite';

document.addEventListener('DOMContentLoaded', () => {
    const carousels = document.querySelectorAll('[data-carousel]');
    carousels.forEach(carousel => {
        new Carousel(carousel);
    });
});
