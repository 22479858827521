<template>
  <div>
    <h1>Trails Page</h1>
    <p>This is the contact page placeholder.</p>
  </div>
</template>

<script>
export default {
  name: 'TrailsPage'
}
</script>
