<!-- File: src/components/SiteFooter.vue -->
<template>
  <footer class="bg-black p-4">
    <div class="w-full mx-auto max-w-screen-xl text-center">
      <span class="text-sm text-gray-500 font-sans">© 2024 Trail Conservancy - All rights reserved</span>
    </div>
  </footer>
</template>

<script setup>
</script>
